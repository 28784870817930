import { trackEvent } from '../config/firebase';

export const AnalyticsService = {
    trackPageView: (pageName: string, pageUrl: string) => {
        trackEvent('page_view', {
            page_name: pageName,
            page_url: pageUrl
        });
    },

    // Product Events
    trackViewItemList: (listId: string, items: any[]) => {
        trackEvent('view_item_list', {
            item_list_name: listId,
            items: items.map(item => ({
                item_id: item._id,
                item_name: item.title,
                price: item.publicationPrice,
            })),
        });
    },

    trackViewItem: (item: any) => {
        trackEvent('view_item', {
            items: [{
                item_id: item._id,
                item_name: item.title,
                price: item.publicationPrice,
            }],
        });
    },

    trackAddToWishlist: (item: any) => {
        trackEvent('add_to_wishlist', {
            items: [{
                item_id: item._id,
                item_name: item.title,
                item_category: 'car',
                price: item.publicationPrice,
            }],
        });
    },

    trackSearch: (searchTerm: string) => {
        trackEvent('view_search_results', {
            search_term: searchTerm
        });
    },

    trackPurchase: (transaction: any) => {
        trackEvent('purchase', {
            transaction_id: transaction.publication,
            value: transaction.total_amount,
        });
    },

    trackSignUp: (method: string) => {
        trackEvent('sign_up', {
            method
        });
    },

    trackPublishCar: (carInfo: any) => {
        trackEvent('publish_car', {
            car_brand: carInfo.brand,
            car_model: carInfo.model,
            car_year: carInfo.year,
            car_price: carInfo.publicationPrice,
        });
    },

    // Funnel Events
    trackFunnelStep: (funnelName: string, stepNumber: number, stepName: string) => {
        trackEvent('funnel_step', {
            funnel_name: funnelName,
            step_number: stepNumber,
            step_name: stepName
        });
    },

    trackButtonClick: (buttonName: string, buttonLocation: string) => {
        trackEvent('button_click', {
            button_name: buttonName,
            button_location: buttonLocation
        });
    }
};