import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

const firebaseConfig = {
    apiKey: "AIzaSyA561uIlmehr7elnH6R-dfScfzo93BPTeU",
    authDomain: "linze-1ad8a.firebaseapp.com",
    projectId: "linze-1ad8a",
    storageBucket: "linze-1ad8a.firebasestorage.app",
    messagingSenderId: "434944730177",
    appId: "1:434944730177:web:dfa611b82f3e423a00d2c0",
    measurementId: "G-5HYFFTBKXK"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const trackEvent = (eventName: string, params?: Record<string, any>) => {
  logEvent(analytics, eventName, params);
};

export { analytics };