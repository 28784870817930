import { useEffect, useState } from 'react';
import styles from './favorites.module.scss';
import { FavoritesProps } from '../../../interfaces/components';
import { useToast } from "../../../helpers/hooks";
import { images } from "../../../constants/images";
import ProfileCard from "../../../components/ProfileCard";
import NoDataList from '../../../components/NoDataList';
import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useMediaQuery } from '@mui/material';
import { addPointsInText, foundCarByBrand } from '../../../helpers/app';
import Favorite from '../../../containers/Favorite/FavoriteContainer';
import TextFieldShared from '../../../components/TextField/TextField';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../../components/Pagination';

const Favorites = ({
    user,
    getFavorites,
    cleanFavorites,
    setFavorites,
    favoritesReducer,
    cleanLikedPublication
}: FavoritesProps) => {
    const toast = useToast();
    const navigate = useNavigate();
    const [favoritesAux, setFavoritesAux] = useState<object[]>([]);
    const [search, setSearch] = useState<string>("");
    const [page, setPage] = useState<number>(1);
    const [totalFavorites, setTotalFavorites] = useState<number>(1);

    const matches = useMediaQuery('(max-width:1024px)');
	const [isSmallScreen, setIsSmallScreen] = useState(matches);
  
	useEffect(() => {
	  setIsSmallScreen(matches);
	}, [matches]);

    const handleClickRow = (event: any, item: any) => {
        event.preventDefault();
        navigate(`/publication/${item._id}`);
    }

    const handleChangeFavorite = (id: string, event: any) => {
        event.stopPropagation();
        const favoritesTemp = favoritesReducer?.data.filter(({ _id }: any) => _id !== id);
        setFavorites(favoritesTemp);
    }

    const handleChangeSearch = (value: any) => {
        let favoritesFiltered = favoritesReducer?.data;
        value = value.toLowerCase();
        setSearch(value);
        if (value) {
            favoritesFiltered = favoritesReducer?.data && favoritesReducer?.data.filter((item: any) => item?.primary_description.toLowerCase().includes(value) || item?.title.toLowerCase().includes(value));
        }
        setFavoritesAux(favoritesFiltered);
    }

    useEffect(() => {
        if (favoritesReducer?.likedFetching === "failure") {
            toast("Ha ocurrido un error!");
            cleanLikedPublication();
        }
        if (favoritesReducer?.likedFetching === "success") {
            cleanLikedPublication();
        }
    }, [favoritesReducer?.likedFetching]);

    useEffect(() => {
        if (favoritesReducer?.fetching === "failure") {
            toast("Ha ocurrido un error al mostrar el listado");
            cleanFavorites();
        }
        if (favoritesReducer?.fetching === "success") {
            cleanFavorites();
            favoritesReducer?.total && setTotalFavorites(Math.ceil(favoritesReducer?.total / 20))
        }
    }, [favoritesReducer?.fetching]);

    useEffect(() => {
        if (!search) {
            setFavoritesAux(favoritesReducer?.data);
        } else {
            handleChangeSearch(search);
        }
    }, [favoritesReducer?.data]);

    useEffect(() => {
        cleanLikedPublication();
        if (user) {
            getFavorites(page);
        }
    }, [page]);

    return (
        favoritesReducer?.fetching === "loading"
            ?
            <div className={styles.loaderFavorites} id="loaderFavorites">
                <CircularProgress
                    size={styles.loader}
                    classes={{
                        colorPrimary: styles.loader
                    }}
                />
                <p className={styles.text}>Cargando favoritos</p>
            </div>
            :
            (favoritesReducer?.data && favoritesReducer?.data.length === 0) || (favoritesAux.length === 0 && !search)
                ?
                <div className={styles.noFavorites}>
                    <NoDataList
                        title="No tienes favoritos aún"
                        text="Presiona el corazón en cualquier auto para convertirlo en favorito y verlo en esta sección"
                        image={images.noFavorites}
                    />
                </div>
                :
                <ProfileCard>
                    {
                        <div className={styles.favoritesContainer}>
                            <div className={styles.search}>
                                <TextFieldShared
                                    variant="search"
                                    label="Buscar por marca o modelo"
                                    name="search"
                                    value={search}
                                    onChange={handleChangeSearch}
                                    className={styles.inputSearch}
                                />
                            </div>
                            <div id="favoritesList" className={styles.list}>
                            {isSmallScreen ?
                                favoritesAux?.map((item: any, index: number) => {
                                    return(
                                        <div className={styles.cardContainer} onClick={(event: any) => handleClickRow(event, item)} key={index}>
                                            <img src={(item?.pictures && item?.pictures[0]?.path) ?? images.noImagePublication} alt="Favorite" />
                                            <div className={styles.cardDescription}>
                                                <p style={{marginRight: "40px"}} className={`${styles.text} ${styles.vehicle}`}>{`${item?.primary_description} ${item?.title}`}</p>
                                                <p className={`${styles.text} ${styles.price}`}>$ {addPointsInText(item?.price)}</p>
                                                <p className={`${styles.text} ${styles.price}`}>{item?.primary_description} - {addPointsInText(item?.secondary_description)}km</p>
                                                <Favorite
                                                    publication={item}
                                                    className={styles.cardFavorite}
                                                    onPress={(event: any) => handleChangeFavorite(item?._id, event)}
                                                    id={item?._id}
                                                    liked={item?.liked}
                                                />
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <TableContainer className={styles.table}>
                                    <Table>
                                        <TableHead>
                                            <TableRow className={styles.head}>
                                                <TableCell className={styles.cell}>Marca</TableCell>
                                                <TableCell className={styles.cell}>Vehiculo</TableCell>
                                                <TableCell className={styles.cell}>Precio</TableCell>
                                                <TableCell className={styles.cell}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                favoritesAux.length === 0
                                                    ?
                                                    <TableRow className={styles.row}>
                                                        <TableCell colSpan={5}>
                                                            <NoDataList
                                                                title="No encontramos resultados para tu búsqueda"
                                                                text="Inténtalo de nuevo o prueba buscando por otra marca o modelo"
                                                                image={images.noFavorites}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                    :
                                                    favoritesAux.map((item: any, index: number) => (
                                                        <TableRow key={index} className={styles.row} onClick={(event: any) => handleClickRow(event, item)}>
                                                            <TableCell className={styles.cell}>
                                                                <div className={styles.picture}>
                                                                    <img src={(item?.pictures && item?.pictures[0]?.path) ?? images.noImagePublication} alt="Favorite" />
                                                                </div>
                                                            </TableCell>
                                                            <TableCell className={`${styles.cell} ${styles.bold} ${styles.title}`}>
                                                                <p className={`${styles.text} ${styles.vehicle}`}>{`${item?.primary_description} ${item?.title}`}</p>
                                                            </TableCell>
                                                            <TableCell className={`${styles.cell} ${styles.bold}`}>
                                                                <p className={`${styles.text} ${styles.price}`}>$ {addPointsInText(item?.price)}</p>
                                                            </TableCell>
                                                            <TableCell className={`${styles.cell} ${styles.bold}`}>
                                                                <Favorite
                                                                    publication={item}
                                                                    onPress={(event: any) => handleChangeFavorite(item?._id, event)}
                                                                    id={item?._id}
                                                                    liked={item?.liked}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                }
                            </div>
                            <Pagination
                                page={page}
                                setPage={setPage}
                                className={styles.pagination}
                                total={totalFavorites}
                            />
                        </div>
                    }
                </ProfileCard>
    )
};
export default Favorites;