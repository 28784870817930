import { memo, useEffect, useMemo, useState } from 'react';
import styles from './detail.module.scss';
import { DetailSellProps } from '../../../interfaces/salePost';
import { useNavigate } from "react-router-dom";
import { OpenModalProps } from "../../../interfaces/components";
import { caracteristics, conforts as confortsImages, images } from '../../../constants/images';
import { MODAL_SELL_TEXTS } from "../../../constants/offers";
import ButtonShared from '../../../components/Button';
import Button from '../../../components/Button';
import Modal from "../../../components/Modal";
import PublicationDetail from '../../../components/PublicationDetail/PublicationDetail';
import { useToast } from '../../../helpers/hooks';
import { capitalize } from '../../../helpers/app';
import { AnalyticsService } from '../../../helpers/analytics';

const Detail = ({
    user,
    salePost,
    clearPostFetching,
    clearSalePost,
    postPublication,
    setLoginRedirection
}: DetailSellProps) => {
    const toast = useToast();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState<OpenModalProps>({ open: false, type: "accepted" });
    const [timeOutMessage, setTimeOutMessage] = useState<any>({open: false, text: ""});

    const handlePressBack = () => {
        navigate("/sell/value_and_payment");
    }

    const handlePressPublish = () => {
        if(!user){
            toast("Debes iniciar sesión");
            setLoginRedirection("/sell/detail");
            navigate("/login");
            return;
        }
        setLoading(true);
        setTimeOutMessage({open: true, text: `Publicando`});
        AnalyticsService.trackPublishCar(publicationNormalized);
        postPublication();
    }

    const onCloseModal = () => {
        clearPostFetching();
        if (salePost?.fetchingPostPublication === "failure") {
            return setOpenModal({...openModal, open: false});
        }
        setOpenModal({...openModal, open: false});
        clearSalePost();
        navigate("/search");
    };

    const publicationNormalized: any = useMemo(() => {
        const conforts = salePost?.confort && salePost?.confort.map((item: any) => (
            {
                name: item?.label,
                value: item?.isSelected ? "Si" : "No",
                icon: confortsImages[item?.id] ?? ""
            }
        ));
        let publicationObject = {
            pictures: salePost?.imagesVideos.filter((image: any) => image.uri ?? image.url ?? image.previewURL ?? image),
            title: `${salePost.brands.selected?.label} ${salePost.models.selected?.label}`,
            year: salePost?.years.selected,
            mileage: salePost?.kms,
            price: salePost?.price,
            chatgpt_description: salePost?.price?.descriptionGPT?.data,
            confort: conforts,
            tags: salePost?.tags,
            version: (salePost?.version?.id !== 0 && salePost?.version?.id !== 1) ? salePost?.version?.name : null,
            caracteristics: [
                {
                    name: "Modelo",
                    value: salePost.models.selected?.label,
                    icon: caracteristics.model
                },
                {
                    name: "Año",
                    value: salePost?.years.selected,
                    icon: caracteristics.year
                },
                {
                    name: "Versión",
                    value: salePost?.version?.name,
                    icon: caracteristics.version
                },
                {
                    name: "Color",
                    value: capitalize(salePost?.color),
                    icon: caracteristics.color
                },
                {
                    name: "Tipo de combustible",
                    value: capitalize(salePost?.fuel),
                    icon: caracteristics.fuel
                },
                {
                    name: "Puertas",
                    value: salePost?.doors,
                    icon: caracteristics.door
                },
                {
                    name: "Transmisión",
                    value: capitalize(salePost?.transmission),
                    icon: caracteristics.transmission
                },
                {
                    name: "Tipo de carrocería",
                    value: capitalize(salePost?.vehicle_type),
                    icon: caracteristics.type
                }
            ]
        }
        return publicationObject;
    }, [salePost]);

    useEffect(() => {
        if (salePost?.fetchingPostPublication === "failure") {
            setLoading(false);
            setOpenModal({type: "rejected", open: true});
            setTimeOutMessage({...timeOutMessage, open: false});
        }
        if (salePost?.fetchingPostPublication === "success") {
            setLoading(false);
            setOpenModal({type:salePost.publicationPublishedStatus === "pending" ? "pending" : "accepted", open: true});
            setTimeOutMessage({...timeOutMessage, open: false});
        }
    }, [salePost?.fetchingPostPublication]);

    useEffect(() => {
        if(!salePost?.plate){
            navigate("/sell");
        }
    }, []);

    return (
        <div className={styles.container}>
            <Modal open={openModal.open} onClose={onCloseModal} variant='white'>
                <div className={styles.modalPublicationContainer}>
                    {["accepted","pending"].includes(openModal?.type) && <img alt="success" src={images.purpleRoundCheckmark} width={120}/>}
                    <p className={styles.title}>{MODAL_SELL_TEXTS[openModal.type].title}</p>
                    <p className={styles.description}>{MODAL_SELL_TEXTS[openModal.type].description}</p>
                    <Button onPress={onCloseModal} title={MODAL_SELL_TEXTS[openModal.type].button} primary />
                </div>
            </Modal>
            <PublicationDetail 
                type="salePost"
                publication={publicationNormalized}
            >
                {
                    <>
                        <ButtonShared 
                            onPress={handlePressBack}
                            title="Regresar" 
                            tertiary
                            className={styles.back} 
                            disabled={loading}
                        />
                        <ButtonShared 
                            onPress={handlePressPublish}
                            title="Publicar" 
                            primary
                            className={styles.publish} 
                            loading={loading}
                        />
                    </>
                }
                <div className={`${styles.timeOut} ${timeOutMessage?.open && styles.show}`}>
                    <p className={styles.loader}>
                        {timeOutMessage?.text}
                        <span className={styles.dotOne}>.</span>
                        <span className={styles.dotTwo}>.</span>
                        <span className={styles.dotThree}>.</span>
                    </p>
                </div>
            </PublicationDetail>
        </div>
    )
}

export default memo(Detail);