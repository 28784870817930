import styles from "./card.module.scss";
import { addPointsInText } from "../../helpers/app";
import { CardProps } from "../../interfaces/components";
import { Link } from 'react-router-dom';
import Favorite from "../../containers/Favorite/FavoriteContainer";
import { Skeleton } from '@mui/material';
import { images } from "../../constants/images";
import Certified from "../Certified/Certified";

const Card = ({
    user,
    item, 
    className = '', 
    navigation = '',
    pressFavorite,
    loading = false,
    redirect = "",
    onClickCard = () => {}
}: CardProps) => {

    const handleChangeFavorite = (event: any) => {
        event.preventDefault();
        if(user && pressFavorite && !loading) pressFavorite();
    }

    return (
        <Link onClick={onClickCard} to={navigation} className={`${styles.card} ${className}`}>
            {
                loading
                ?   <Skeleton animation="wave" variant="rounded" className={styles.imageCard} />
                :   <img loading="lazy" src={item?.pictures[0]?.path ?? images.noImagePublication} className={styles.imageCard} alt={`${item?.title} ${item?.vehicle_data?.version ?? ""} año ${item?.primary_description}`} />
            }
            {
                !loading && item?.status_brief?.code === "sold_out" && 
                <h3 className={styles.sold}>Vendido</h3>
            }
            {
                !loading && item?.certified &&
                <Certified className={styles.certified} />
            }
            <div className={styles.information}>
                <div className={styles.info}>
                    <p className={styles.text}>
                        {
                            loading
                            ? <Skeleton animation="wave" variant="text" className={`${styles.name} ${styles.skeleton}`} />
                            : <span className={styles.name}>{item?.title}</span>
                        }
                        {
                            loading
                            ? <Skeleton animation="wave" variant="text" className={`${styles.data} ${styles.skeleton}`} />
                            : <span className={styles.data}>{item?.primary_description}, {addPointsInText(item?.secondary_description)}km</span>
                        }
                    </p>
                    <Favorite 
                        publication={item}
                        onPress={handleChangeFavorite}
                        id={item?._id}
                        liked={item?.liked}
                        loading={loading}
                        redirect={redirect}
                    />
                </div>
                <p className={styles.price}>
                    {
                        loading
                        ? <Skeleton animation="wave" variant="text" className={`${styles.principal} ${styles.skeleton}`} />
                        : <span className={styles.principal}>$ {addPointsInText(item?.price)}</span>
                    }
                </p>
            </div>
        </Link>
    )
}
export default Card;