import styles from "./footer.module.scss";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { FooterProps } from "../../interfaces/components";
import { useWindowSize } from "use-hooks";
import { images } from "../../constants/images";
import { AnalyticsService } from "../../helpers/analytics";

const Footer = ({
    user,
    searchType,
    setTypeReducer
}: FooterProps) => {
    const location = useLocation();
    const windowSize = useWindowSize();
    const navigate = useNavigate();

    const handlePressFilter = (value: string) => {
        if(value === ""){
            setTypeReducer([]);
            return;
        }
        if(value === 'van' || value === 'deportivo') setTypeReducer([]);
        else setTypeReducer([value]);
    }

    return (
        <footer className={styles.footerContainer}>
            <section className={styles.footerContent}>
                <div className={styles.leftFooter}>
                    <img src={images.isoLogoTipo} />
                    <p>Un producto de Linzecar SPA®.</p>
                </div>
                <div className={styles.rightFooter}>
                    <div className={styles.rowFooter}>
                        <div style={{display: "flex", flexDirection: "row", gap: "32px", alignItems: "center", position: "absolute", right: 0, left: 0, justifyContent: "center", bottom: windowSize.width > 1324 ? "80px" : "88px"}}>
                            <NavLink to='/search' onClick={() => AnalyticsService.trackButtonClick('buy', 'footer')} className={styles.aLink}>
                                Comprar
                            </NavLink>
                            <NavLink to={'/sell'} onClick={() => AnalyticsService.trackButtonClick('sell', 'footer')} className={styles.aLink}>
                                Vender
                            </NavLink>
                            <NavLink to={'/login'} className={styles.aLink}>
                                Ingresar
                            </NavLink>
                        </div>
                        <div className={styles.socialNetworksContainer}>
                            <img onClick={() => window.open("https://www.instagram.com/linzecar.chile?igsh=MWZtejMwZjlobjFsaQ==","_blank")} src={images.igIconFooter} />
                            <img onClick={() => window.open("https://www.facebook.com/Linzecar.chile/","_blank")} src={images.fbIconFooter} />
                            <img onClick={() => window.open("https://www.linkedin.com/company/linze-car/about/","_blank")} src={images.linkedinIconFooter} />
                        </div>
                    </div>
                    <div  style={{display: "flex", flexDirection: windowSize.width > 1324 ? "row" : "column", gap: windowSize.width > 1324 ? "24px" :"10px", alignItems: "center", position: "absolute", right: 0, left: 0, justifyContent: "center", bottom: "36px",}}>
                        <p style={{color: "#595959"}}>Copyright © 2023 Linze. Todos los derechos reservados</p>
                        <p style={{color: "#595959", display: windowSize.width > 1324 ?"block" : "none"  }}>|</p>
                        <p style={{color: "#595959"}}>Visita nuestros <span onClick={() => navigate("/terms")}><u style={{fontWeight: "500", cursor: "pointer"}}>términos y condiciones</u></span></p>
                    </div>
                </div>
            </section>
        </footer>
    )
}
export default Footer;