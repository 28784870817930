import { Dispatch } from '@reduxjs/toolkit';
import { api, dev } from '../config/api';
import { GetState } from '../config/store';
import { Brand, ConfortItem, Model, RecordsProps, SuggestedPriceObjectProps, versionsObjectProps } from '../interfaces/salePost';
import {
    initialFlow as initialFlowReducer,
    clearPublicationPostFetching,
    clearSalePostReducer,
    getAdditionalExpensesSalePostFailure,
    getAdditionalExpensesSalePostRequest,
    getAdditionalExpensesSalePostSuccess,
    getIsPlateRepeatedFailure,
    getIsPlateRepeatedRequest,
    getIsPlateRepeatedSuccess,
    getPlateInfoFailure,
    getPlateInfoRequest,
    getPlateInfoSuccess,
    getSuggestedPriceFailure,
    getSuggestedPriceRequest,
    getSuggestedPriceSuccess,
    getBrandsRequest,
    getBrandsSuccess,
    getBrandsFailure,
    cleanGetBrands as cleanGetBrandsReducer,
    getYearsRequest,
    getYearsSuccess,
    getYearsFailure,
    cleanGetYears as cleanGetYearsReducer,
    getModelsRequest,
    getModelsSuccess,
    getModelsFailure,
    cleanGetModels as cleanGetModelsReducer,
    getVersionsFailure,
    getVersionsRequest,
    getVersionsSuccess,
    postPublicationRequest,
    postPublicationFailure,
    postPublicationSuccess,
    postPublicationsImagesRequest,
    postPublicationsImagesSuccess,
    postPublicationsImagesFailure,
    deletePublicationsImageRequest,
    deletePublicationsImageSuccess,
    deletePublicationsImageFailure,
    cleanDeleteImage as cleanDeleteImageReducer,
    setAditionalFuelReducer,
    setFuelReducer,
    setPublicationBrandReducer,
    setPublicationColorReducer,
    setPublicationConfortReducer,
    setPublicationCountryReducer,
    setPublicationImagesReducer,
    setPublicationRecordReducer,
    setPublicationDoorsReducer,
    setPublicationIsFinancingBonusReducer,
    setPublicationIsNegotiatePriceReducer,
    setPublicationIsPaidReducer,
    setPublicationKmsReducer,
    setPublicationModelReducer,
    setPublicationPlateReducer,
    setPublicationPriceReducer,
    setPublicationRegionReducer,
    setPublicationTagsReeducer,
    setPublicationVersionReducer,
    setPublicationYearReducer,
    cleanPlateRepeated as cleanPlateRepeatedReducer,
    getDescriptionGPTRequest,
    getDescriptionGPTSuccess,
    getDescriptionGPTFailure,
    cleanDescriptionGPT,
    setSuggestedPrices as setSuggestedPricesReducer,
    setPublicationTypeReducer,
    setPublicationTransmissionReducer,
    setDescriptionReducer
} from '../reducers/salePost';
import { axiosClient } from '../config/axios';

export const initialFlow = () => (dispatch: Dispatch) => _initialFlow(dispatch);
const _initialFlow = (dispatch: Dispatch) => dispatch({ type: initialFlowReducer });

export const setImages = (data: any[]) => (dispatch: Dispatch) => _setImages(data, dispatch);
const _setImages = (data: any[], dispatch: Dispatch) => dispatch({ type: setPublicationImagesReducer, payload: data });

export const setBrand = (data: Brand | null) => (dispatch: Dispatch) => _setBrand(data, dispatch);
const _setBrand = (data: Brand | null, dispatch: Dispatch) => dispatch({ type: setPublicationBrandReducer, payload: data });

export const setYear = (data: string | null) => (dispatch: Dispatch) => _setYear(data, dispatch);
const _setYear = (data: string | null, dispatch: Dispatch) => dispatch({ type: setPublicationYearReducer, payload: data });

export const setModel = (data: Model | null) => (dispatch: Dispatch) => _setModel(data, dispatch);
const _setModel = (data: Model | null, dispatch: Dispatch) => dispatch({ type: setPublicationModelReducer, payload: data });

export const setVersion = (data: any) => (dispatch: Dispatch) => _setVersion(data, dispatch);
const _setVersion = (data: any, dispatch: Dispatch) => dispatch({ type: setPublicationVersionReducer, payload: data });

export const setTags = (data: any | null) => (dispatch: Dispatch) => _setTags(data, dispatch);
const _setTags = (data: any | null, dispatch: Dispatch) => dispatch({ type: setPublicationTagsReeducer, payload: data });

export const setConfort = (data: ConfortItem[]) => (dispatch: Dispatch) => _setConfort(data, dispatch);
const _setConfort = (data: ConfortItem[], dispatch: Dispatch) => dispatch({ type: setPublicationConfortReducer, payload: data });

export const setColor = (data: string) => (dispatch: Dispatch) => _setColor(data, dispatch);
const _setColor = (data: string, dispatch: Dispatch) => dispatch({ type: setPublicationColorReducer, payload: data });

export const setKms = (data: string) => (dispatch: Dispatch) => _setKms(data, dispatch);
const _setKms = (data: string, dispatch: Dispatch) => dispatch({ type: setPublicationKmsReducer, payload: data });

export const setPlate = (data: string) => (dispatch: Dispatch) => _setPlate(data, dispatch);
const _setPlate = (data: string, dispatch: Dispatch) => dispatch({ type: setPublicationPlateReducer, payload: data });

export const setPrice = (data: string) => (dispatch: Dispatch) => _setPrice(data, dispatch);
const _setPrice = (data: string, dispatch: Dispatch) => dispatch({ type: setPublicationPriceReducer, payload: data });

export const setRecord = (data: RecordsProps) => (dispatch: Dispatch) => _setRecord(data, dispatch);
const _setRecord = (data: RecordsProps, dispatch: Dispatch) => dispatch({ type: setPublicationRecordReducer, payload: data });

export const setDoors = (doors: number) => (dispatch: Dispatch) => _setDoors(doors, dispatch);
const _setDoors = (doors: number, dispatch: Dispatch) => dispatch({ type: setPublicationDoorsReducer, payload: doors });

export const setFinancingBonus = (data: boolean) => (dispatch: Dispatch) => _setFinancingBonus(data, dispatch);
const _setFinancingBonus = (data: boolean, dispatch: Dispatch) => dispatch({ type: setPublicationIsFinancingBonusReducer, payload: data });

export const setIsNegotiate = (data: boolean) => (dispatch: Dispatch) => _setIsNegotiate(data, dispatch);
const _setIsNegotiate = (data: boolean, dispatch: Dispatch) => dispatch({ type: setPublicationIsNegotiatePriceReducer, payload: data });

export const setIsPaid = (data: boolean) => (dispatch: Dispatch) => _setIsPaid(data, dispatch);
const _setIsPaid = (data: boolean, dispatch: Dispatch) => dispatch({ type: setPublicationIsPaidReducer, payload: data });

export const setCountry = (country: string) => (dispatch: Dispatch) => _setCountry(country, dispatch);
const _setCountry = (country: string, dispatch: Dispatch) => dispatch({ type: setPublicationCountryReducer, payload: country });

export const setRegion = (region: string) => (dispatch: Dispatch) => _setRegion(region, dispatch);
const _setRegion = (region: string, dispatch: Dispatch) => dispatch({ type: setPublicationRegionReducer, payload: region });

export const setType = (type: string) => (dispatch: Dispatch) => _setType(type, dispatch);
const _setType = (type: string, dispatch: Dispatch) => dispatch({ type: setPublicationTypeReducer, payload: type });

export const setTransmission = (transmission: string) => (dispatch: Dispatch) => _setTransmission(transmission, dispatch);
const _setTransmission = (transmission: string, dispatch: Dispatch) => dispatch({ type: setPublicationTransmissionReducer, payload: transmission });

export const cleanPlateRepeated = () => (dispatch: Dispatch) => _cleanPlateRepeated(dispatch);
const _cleanPlateRepeated = (dispatch: Dispatch) => dispatch({ type: cleanPlateRepeatedReducer });

export const getPlateInfo = (license_plate: string) => (dispatch: Dispatch) => _getPlateInfo(license_plate, dispatch);
const _getPlateInfo = async (license_plate: string, dispatch: Dispatch) => {
    try {
        dispatch({ type: getPlateInfoRequest, payload: license_plate });

        const response = await axiosClient.post(`${api.autoRed}/plates`, { license_plate: license_plate.toUpperCase() });
        
        dispatch({ type: getPlateInfoSuccess, payload: response?.data });
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: getPlateInfoFailure });
    }
}

export const getBrands = () => (dispatch: Dispatch) => _getBrands(dispatch);
const _getBrands = async (dispatch: Dispatch) => {
    try {
        dispatch({ type: getBrandsRequest });

        const response = await axiosClient.get(`${api.autoRed}/brands`);

        dispatch({ type: getBrandsSuccess, payload: response?.data.data.brands });
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: getBrandsFailure });
    }
}

export const cleanGetBrands = () => (dispatch: Dispatch) => _cleanGetBrands(dispatch);
const _cleanGetBrands = async (dispatch: Dispatch) => dispatch({ type: cleanGetBrandsReducer });

export const getYears = () => (dispatch: Dispatch) => _getYears(dispatch);
const _getYears = async (dispatch: Dispatch) => {
    try {
        dispatch({ type: getYearsRequest });

        const response = await axiosClient.get(`${api.settings}/parameters/vehicle_years`);

        dispatch({ type: getYearsSuccess, payload: response?.data.data.parameter });
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: getYearsFailure });
    }
}

export const cleanGetYears = () => (dispatch: Dispatch) => _cleanGetYears(dispatch);
const _cleanGetYears = async (dispatch: Dispatch) => dispatch({ type: cleanGetYearsReducer });

export const getModels = (car_id: string) => (dispatch: Dispatch) => _getModels(car_id, dispatch);
const _getModels = async (car_id: string, dispatch: Dispatch) => {
    try {
        dispatch({ type: getModelsRequest });

        const response = await axiosClient.get(`${api.autoRed}/models/${car_id}`);

        dispatch({ type: getModelsSuccess, payload: response?.data });
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: getModelsFailure });
    }
}

export const cleanGetModels = () => (dispatch: Dispatch) => _cleanGetModels(dispatch);
const _cleanGetModels = async (dispatch: Dispatch) => dispatch({ type: cleanGetModelsReducer });

export const getVersions = (versionsObject: versionsObjectProps) => (dispatch: Dispatch) => _getVersions(versionsObject, dispatch);
const _getVersions = async (versionsObject: versionsObjectProps, dispatch: Dispatch) => {
    try {
        dispatch({ type: getVersionsRequest });

        const response = await axiosClient.post(`${api.autoRed}/versions`, versionsObject);

        dispatch({ type: getVersionsSuccess, payload: response?.data });
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: getVersionsFailure });
    }
}

export const getSuggestedPrice = (suggestedPriceObject: SuggestedPriceObjectProps) => (dispatch: Dispatch) => _getSuggestedPrice(suggestedPriceObject, dispatch);
const _getSuggestedPrice = async (suggestedPriceObject: SuggestedPriceObjectProps, dispatch: Dispatch) => {
    try {
        dispatch({ type: getSuggestedPriceRequest });


        const response = await axiosClient.post(`${api.autoRed}/suggested-prices`, suggestedPriceObject);

        dispatch({
            type: getSuggestedPriceSuccess,
            payload: {
                retake: response?.data?.retake?.suggested_price,
                publication: response?.data?.publication?.suggested_price,
                sale: response?.data?.sale?.suggested_price
            }
        });
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: getSuggestedPriceFailure });
    }
}

export const setSuggestedPrices = (prices: any) => (dispatch: Dispatch) => _setSuggestedPrices(prices, dispatch);
const _setSuggestedPrices = (prices: any, dispatch: Dispatch) => dispatch({ type: setSuggestedPricesReducer, payload: prices });

export const postPublicationsImages = (formData: any, id: number) => (dispatch: Dispatch, getState: GetState) => _postPublicationsImages(formData, id, dispatch, getState);
const _postPublicationsImages = async (formData: any, id: number, dispatch: Dispatch, getState: GetState) => {
    try {
        dispatch({ type: postPublicationsImagesRequest });
        const token = getState().auth.token;

        const response = await fetch(process.env.REACT_APP_API_PUBLICATIONS_FILES || "", {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            body: formData
        });

        const json = await response.json();
        if (!json.success) throw json;

        const images = getState().salePost.imagesVideos;

        const newImages = images && images.map((image: any) => {
            if (image.name === json?.data?.file?.original) {
                return {
                    id: json.data.file.id,
                    uri: json.data.file.path,
                    name: image.name,
                    upload: "success"
                }
            }
            return image
        });

        dispatch({ type: postPublicationsImagesSuccess, payload: newImages })

    } catch (error: any) {
        console.log(error)
        const images = getState().salePost.imagesVideos;
        const newImages = images && images.map((image: any) => {
            if (image.id === id) {
                return {
                    ...image,
                    upload: "failure"
                }
            }
            return image;
        });
        dispatch({ type: postPublicationsImagesFailure, payload: newImages });
    }
}

export const deletePublicationImage = (id: string) => (dispatch: Dispatch) => _deletePublicationImage(id, dispatch);
const _deletePublicationImage = async (id: string, dispatch: Dispatch) => {
    try {
        dispatch({ type: deletePublicationsImageRequest })

        const response = await axiosClient.delete(`${api.publications}/files/${id}`);

        dispatch({ type: deletePublicationsImageSuccess, payload: response?.data.data.deleted_file })
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: deletePublicationsImageFailure, payload: id });
    }
}

export const cleanDeleteImage = () => (dispatch: Dispatch) => _cleanDeleteImage(dispatch);
const _cleanDeleteImage = (dispatch: Dispatch) => dispatch({ type: cleanDeleteImageReducer });

export const clearSalePost = () => (dispatch: Dispatch) => _clearSalePost(dispatch);
const _clearSalePost = (dispatch: Dispatch) => dispatch({ type: clearSalePostReducer });

export const clearPostFetching = () => (dispatch: Dispatch) => _clearPostFetching(dispatch);
const _clearPostFetching = (dispatch: Dispatch) => dispatch({ type: clearPublicationPostFetching });

export const deletePublicationsImage = (name: string) => (dispatch: Dispatch) => _deletePublicationsImage(name, dispatch);
const _deletePublicationsImage = async (name: string, dispatch: Dispatch) => {
    try {
        dispatch({ type: deletePublicationsImageRequest })

        const response = await axiosClient.delete(`${api.publications}/files/${name}`);

        dispatch({ type: deletePublicationsImageSuccess, payload: response?.data?.data.deleted_file })
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: deletePublicationsImageFailure })
    }
};

export const setFuel = (fuel: string) => (dispatch: Dispatch) => _setFuel(fuel, dispatch)
const _setFuel = (fuel: string, dispatch: Dispatch) => dispatch({ type: setFuelReducer, payload: fuel })

export const setAditionalFuel = (aditionalFuel: string) => (dispatch: Dispatch) => _setAditionalFuel(aditionalFuel, dispatch)
const _setAditionalFuel = (aditionalFuel: string, dispatch: Dispatch) => dispatch({ type: setAditionalFuelReducer, payload: { aditionalFuel: aditionalFuel } });

export const getIsPlateRepeated = (plate: string) => (dispatch: Dispatch) => _getIsPlateRepeated(plate, dispatch);
const _getIsPlateRepeated = async (plate: string, dispatch: Dispatch) => {
    try {
        dispatch({ type: getIsPlateRepeatedRequest, payload: plate });

        await axiosClient.get(`${api.publications}/articles/exist-value?plate=${plate}`);

        dispatch({ type: getIsPlateRepeatedSuccess })
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: getIsPlateRepeatedFailure });
    }
};

export const getAdditionalExpensesSalePost = (price: string) => (dispatch: Dispatch) => _getAdditionalExpensesSalePost(price, dispatch);
const _getAdditionalExpensesSalePost = async (price: string, dispatch: Dispatch) => {
    try {
        dispatch({ type: getAdditionalExpensesSalePostRequest });

        const response = await axiosClient.get(`${api.settings}/additional-costs/calculate?operation_type=sale&operation_flow=publication&price=${price}`);

        dispatch({ type: getAdditionalExpensesSalePostSuccess, payload: response?.data.data })

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: getAdditionalExpensesSalePostFailure });
    }
};

export const postPublication = () => (dispatch: Dispatch, getState: GetState) => _postPublication(dispatch, getState);
const _postPublication = async (dispatch: Dispatch, getState: GetState) => {
    try {
        const publication = getState().salePost;

        let conforts: any = {};
        publication.confort.forEach((element) => {
            conforts[element.id] = element.isSelected;
        });

        const pictures = publication.imagesVideos.filter((img: any) => img.uri);

        const pub = {
            article: {
                brand: publication.brands?.selected?.label,
                brand_id: publication.brands?.selected?.id,
                model: publication.models?.selected?.label,
                model_id: publication.models?.selected?.id,
                version: publication.version?.name,
                version_id: publication.version?.id,
                year: publication.years.selected,
                mileage: publication.kms,
                color: publication.color,
                plate: publication.plate,
                price: publication.price,
                pick_up_price: publication.suggestedPrices?.data?.retake ? publication.suggestedPrices?.data?.retake : publication.price,
                sale_price: publication.suggestedPrices?.data?.sale ? publication.suggestedPrices?.data?.sale : publication.price,
                transmission: publication.transmission,
                type: publication.vehicle_type ?? "Vehīculo",
                confort: conforts,
                fuel: publication.fuel,
                aditional_fuel: publication.aditionalFuel,
                vehicle_paid: publication.vehiclePaid,
                doors: publication.doors,
            },
            tags: publication.tags,
            user_description: publication.userDescription,
            pictures: pictures.map((img: any) => img.id),
            country: publication.country,
            region: publication.region,
            record: {
                accidents_reported: publication.record.accidentsReported,
                personal_use: publication.record.personalUse,
                sole_owner: publication.record.soleOwner
            },
            detail: {
                negotiate: publication.negotiatePrice,
                bonus_info: {
                    bonus: publication.financingBonus,
                    value: 0
                }
            }
        };

        dispatch({ type: postPublicationRequest });

        const body = JSON.stringify(pub);

        const response = await axiosClient.post(`${api.publications}`, body);
        dispatch({ type: postPublicationSuccess, payload: { publication, status: response?.data?.data?.publication?.status_brief?.code } })
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: postPublicationFailure })
    }
};

export const getDescriptionChatGPT = (brand: string, model: string, year: string) => (dispatch: Dispatch) => _getDescriptionChatGPT(brand, model, year, dispatch);
const _getDescriptionChatGPT = async (brand: string, model: string, year: string, dispatch: Dispatch) => {
    try {
        dispatch({ type: getDescriptionGPTRequest });

        const obj = {
            brand: brand,
            model: model,
            year: year
        }

        const body = JSON.stringify(obj);

        const response = await axiosClient.post(`${api.openai}/description`, body);

        dispatch({ type: getDescriptionGPTSuccess, payload: response?.data.data.car_description })
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        if (errorResponse?.code) return dispatch({ type: getDescriptionGPTFailure, payload: errorResponse.code });
        dispatch({ type: getDescriptionGPTFailure, payload: "Ha ocurrido un error" });
    }
};

export const cleanDescriptionChatGPT = () => (dispatch: Dispatch) => _cleanDescriptionChatGPT(dispatch)
const _cleanDescriptionChatGPT = (dispatch: Dispatch) => dispatch({ type: cleanDescriptionGPT });


export const setUserDescription = (description: string) => (dispatch: Dispatch) => _setUserDescription(description)(dispatch)
const _setUserDescription = (description: string) => (dispatch: Dispatch) => dispatch({ type: setDescriptionReducer, payload: description })