import { Dispatch } from 'redux';
import { api, dev } from '../config/api';
import { GetState } from "../config/store";
import {
    setUploadImagesReducer,
    getPublicationRequest,
    getPublicationSuccess,
    getPublicationFailure,
    cleanPublication as cleanPublicationReducer,
    getPublicationArticlesRequest,
    getPublicationArticlesSuccess,
    getPublicationArticlesFailure,
    cleanPublicationArticles as cleanPublicationArticlesReducer,
    updatePublicationRequest,
    updatePublicationSuccess,
    updatePublicationFailure,
    postImagesPublicationRequest,
    postImagesPublicationSuccess,
    postImagesPublicationFailure,
    deleteImagesPublicationRequest,
    deleteImagesPublicationSuccess,
    deleteImagesPublicationFailure,
    cleanUpdate as cleanUpdateReducer,
    cleanAll as cleanAllReducer,
    postOfferRequest,
    postOfferSuccess,
    postOfferFailure,
    cleanOfferPost as cleanOfferPostReducer,
    getOfferAcceptedRequest,
    getOfferAcceptedSuccess,
    getOfferAcceptedFailure,
    cleanOfferAccepted as cleanOfferAcceptedReducer,
    getOfferBuyRequest,
    getOfferBuySuccess,
    getOfferBuyFailure,
    cleanOffersBuy as cleanOffersBuyReducer,
    getOffersPublicationRequest,
    getOffersPublicationSuccess,
    getOffersPublicationFailure,
    putOffersPublicationRequest,
    putOffersPublicationSuccess,
    putOffersPublicationFailure,
    cleanOfferPut as cleanOfferPutReducer,
    getInspectionRequest,
    getInspectionSuccess,
    getInspectionFailure,
    getInspectionFilesRequest,
    getInspectionFilesSuccess,
    getInspectionFilesFailure,
    cleanInspectionFiles as cleanInspectionFilesReducer,
    getLinzerAssignedRequest,
    getLinzerAssignedSuccess,
    getLinzerAssignedFailure,
    cleanOffer as cleanOfferReducer,
    cleanInspection as cleanInspectionReducer,
    deletePublicationRequest,
    deletePublicationSuccess,
    deletePublicationFailure,
    cleanDeletePublication as cleanDeletePublicationReducer,
    getHistoryRequest,
    getHistorySuccess,
    getHistoryFailure,
    cleanHistory as cleanHistoryReducer,
    getRecordsRequest,
    getRecordsSuccess,
    getRecordsFailure,
    cleanGetRecords as cleanGetRecordsReducer,
    getViewsRequest,
    getViewsSuccess,
    getViewsFailure,
    cleanGetViews as cleanGetViewsReducer,
    getInterestedRequest,
    getInterestedSuccess,
    getInterestedFailure,
    cleanGetInterested as cleanGetInterestedReducer,
    getCounterOffersRequest,
    getCounterOffersSuccess,
    getCounterOffersFailure,
    cleanGetCounterOffers as cleanGetCounterOffersReducer,
    postCounterOffersRequest,
    postCounterOffersSuccess,
    postCounterOffersFailure,
    cleanPostCounterOffers as cleanPostCounterOffersReducer,
    putCounterOffersRequest,
    putCounterOffersSuccess,
    putCounterOffersFailure,
    cleanPutCounterOffers as cleanPutCounterOffersReducer,
    cleanImagesPublicationSaved,
    pausePublicationRequest,
    pausePublicationSuccess,
    pausePublicationFailure,
    cleanPausePublication as cleanPausePublicationReducer,
    setPublicationReducer,
    cleanArticleAndPublication as cleanArticleAndPublicationReducer
} from '../reducers/publication';
import { StatusOfferTypes, InspectionTypes } from '../interfaces/types';
import { axiosClient } from '../config/axios';
import { accumulateRequest, setAccumulateRequest } from '../reducers/requests';

export const setPublication = (data: any) => (dispatch: Dispatch) => _setPublication(data, dispatch);
const _setPublication = async (data: any, dispatch: Dispatch, ) => dispatch({ type: setPublicationReducer, payload: data });

export const cleanArticleAndPublication = () => (dispatch: Dispatch) => _cleanArticleAndPublication(dispatch);
const _cleanArticleAndPublication = async (dispatch: Dispatch) => dispatch({ type: cleanArticleAndPublicationReducer });

export const setUploadImages = (data: any) => (dispatch: Dispatch) => _setUploadImages(data, dispatch);
const _setUploadImages = async (data: any, dispatch: Dispatch, ) => dispatch({ type: setUploadImagesReducer, payload: data });

export const getPublication = (id: string) => (dispatch: Dispatch, getState: GetState) => _getPublication(id, dispatch, getState);
const _getPublication = async (id: string, dispatch: Dispatch, getState: GetState) => {
    try {
        const {fetchingFirstTime} = getState().auth;

        if(fetchingFirstTime){
            dispatch({type: accumulateRequest, payload: {function: getPublication, params: {id}, key: "getPublication"} });
            return;
        }
        
        dispatch({ type: getPublicationRequest });

        const response = await axiosClient.get(`${api.publications}/${id}?with_pictures=true&deleted=true`);
        
        const cachedRequests = getState().requests.data;
        if(cachedRequests?.length){
            cachedRequests.find((cachedRequest) =>{
                if(cachedRequest?.key === "getPublication"){
                    dispatch({type: setAccumulateRequest, payload: cachedRequests.filter((cached) => cached?.key !== "getPublication") })
                }
            })
        };

        dispatch({ type: getPublicationSuccess, payload: response?.data?.data.publication });
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: getPublicationFailure, payload: errorResponse?.code });
    }
}

export const cleanPublication = () => (dispatch: Dispatch) => _cleanPublication(dispatch);
const _cleanPublication = (dispatch: Dispatch) => dispatch({ type: cleanPublicationReducer });

export const getArticlesFromPublication = (id: string) => (dispatch: Dispatch) => _getArticlesFromPublication(id, dispatch);
const _getArticlesFromPublication = async (id: string, dispatch: Dispatch) => {
    try {
        dispatch({ type: getPublicationArticlesRequest });

        const response = await axiosClient.get(`${api.publications}/${id}/articles`);
        
        dispatch({ type: getPublicationArticlesSuccess, payload: response?.data.data.article });

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: getPublicationArticlesFailure, payload: errorResponse?.code });
    }
}

export const cleanPublicationArticles = () => (dispatch: Dispatch) => _cleanPublicationArticles(dispatch);
const _cleanPublicationArticles = (dispatch: Dispatch) => dispatch({ type: cleanPublicationArticlesReducer });

export const postPublicationImages = (formData: any, reloaded = false) => (dispatch: Dispatch, getState: GetState) => _postPublicationImages(formData, reloaded, dispatch, getState);
const _postPublicationImages = async (formData: any, reloaded: boolean, dispatch: Dispatch, getState: GetState) => {
    try {
        dispatch({type: postImagesPublicationRequest})
        const token = getState().auth.token;

        const response = await fetch(process.env.REACT_APP_API_PUBLICATIONS_FILES || "", {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            body: formData
        });

        const json = await response.json();
        if (!json.success) throw json;
        
        const images = getState().publication.update.newImages.data;

        const newImages = images && images.map((image: any) => {
            if(image.name === json?.data?.file?.original){
                return {
                    ...image,
                    error: false,
                    id: json.data.file.id,
                    path: json.data.file.path,
                } 
             }
             return image
        });

        dispatch({type: postImagesPublicationSuccess, payload: { data: newImages , reloaded, reloadedName: json?.data?.file?.original }});
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        if(formData){
            const data = formData.get("file");
            const currentNewImages = [...getState().publication?.update.newImages.data];
            const newImages = currentNewImages && currentNewImages.map((image: any) => {
                if(image.name === data?.name){
                    return {
                        ...image,
                        error: true
                    } 
                 }
                 return image
            });
            dispatch({type: postImagesPublicationFailure, payload: {newImages}});
        }
    }
};

export const cleanImagesSaved = () => (dispatch: Dispatch) => _cleanImagesSaved(dispatch);
const _cleanImagesSaved  = async (dispatch: Dispatch) => dispatch({type: cleanImagesPublicationSaved});

export const deletePublicationImage = (id: string) => (dispatch: Dispatch) => _deletePublicationImage(id, dispatch);
const _deletePublicationImage = async (id: string, dispatch: Dispatch) => {
    try {
        dispatch({type: deleteImagesPublicationRequest})

        const response = await axiosClient.delete(`${api.publications}/files/${id}`);

        dispatch({type: deleteImagesPublicationSuccess, payload: response?.data.data.deleted_file})
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({type: deleteImagesPublicationFailure})
    }
}

export const updatePublication = (publicationObject: any) => (dispatch: Dispatch, getState: GetState) => _updatePublication(publicationObject, dispatch, getState);
const _updatePublication = async (publicationObject: any, dispatch: Dispatch, getState: GetState) => {
    try {
        dispatch({ type: updatePublicationRequest });
        const publicationId = getState().publication.data._id;
        const body = JSON.stringify(publicationObject);
        
        const response = await axiosClient.patch(`${api.publications}/${publicationId}`, body);

        dispatch({ type: updatePublicationSuccess, payload: response?.data.data.publication });
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        if(errorResponse.message) {
            return dispatch({ 
                type: updatePublicationFailure, 
                payload: {
                    code: errorResponse.code, 
                    message: errorResponse.message
                } 
            });
        }
        dispatch({ type: updatePublicationFailure });
    }
}

export const getOffersPublication = (id: string) => (dispatch: Dispatch, getState: GetState) => _getOffersPublication(id, dispatch, getState);
const _getOffersPublication = async (id: string, dispatch: Dispatch, getState: GetState) => {
    try {
        const {fetchingFirstTime} = getState().auth;

        if(fetchingFirstTime){
            dispatch({type: accumulateRequest, payload: {function: getOffersPublication, params: {id}, key: "getOffersPublication"} });
            return;
        }

        dispatch({type: getOffersPublicationRequest})

        const response = await axiosClient.get(`${api.offers}/${id}/offers/received-offers`);

        const cachedRequests = getState().requests.data;
        if(cachedRequests?.length){
            cachedRequests.find((cachedRequest) =>{
                if(cachedRequest?.key === "getOffersPublication"){
                    dispatch({type: setAccumulateRequest, payload: cachedRequests.filter((cached) => cached?.key !== "getOffersPublication") })
                }
            })
        };

        dispatch({ type: getOffersPublicationSuccess, payload: response?.data.data.record_offer})
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({type: getOffersPublicationFailure});
    }
};

export const putOffersPublication = (id: string, status: {status: StatusOfferTypes}) => (dispatch: Dispatch) => _putOffersPublication(id, status, dispatch);
const _putOffersPublication = async (id: string, status: {status: StatusOfferTypes}, dispatch: Dispatch) => {
    try {
        dispatch({type: putOffersPublicationRequest});

        const body = JSON.stringify({status: status});

        const response = await axiosClient.put(`${api.publications}/offers/${id}/status`, body);

        dispatch({ type: putOffersPublicationSuccess, payload: response?.data.data.offer})

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        if(errorResponse){
            return dispatch({ 
                type: putOffersPublicationFailure, 
                payload: {
                    code: errorResponse?.code, 
                    message: errorResponse?.message
                }
            });
        }
        dispatch({type: putOffersPublicationFailure});
    }
};

export const cleanOfferPut = () => (dispatch: Dispatch) => _cleanOfferPut(dispatch);
const _cleanOfferPut = (dispatch: Dispatch) => dispatch({ type: cleanOfferPutReducer });

export const postOfferRequestReducer = (id: string, offer: number) => (dispatch: Dispatch) => _postOfferRequestReducer(id, offer, dispatch)
const _postOfferRequestReducer = async (id: string, offer: number, dispatch: Dispatch) => {
    try {
        dispatch({ type: postOfferRequest });
        const body = JSON.stringify({publication: id, offer: offer});

        await axiosClient.post(`${api.publications}/offers`, body);

        dispatch({ type: postOfferSuccess });
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        if(errorResponse) return dispatch({ 
            type: postOfferFailure, 
            payload: {
                code: errorResponse?.code, 
                message: errorResponse?.message
            }
        })
        dispatch({ type: postOfferFailure })
    }
}

export const getOfferAccepted = (id: string) => (dispatch: Dispatch, getState: GetState) => _getOfferAccepted(id, dispatch, getState);
const _getOfferAccepted= async(id: string, dispatch: Dispatch, getState: GetState) => {
    try {
        const {fetchingFirstTime} = getState().auth;

        if(fetchingFirstTime){
            dispatch({type: accumulateRequest, payload: {function: getOfferAccepted, params: {id}, key: "getOfferAccepted"} });
            return;
        }
        
        const userId = getState().auth.user?._id;

        if(!userId) {
            const cachedRequests = getState().requests.data;
            if(cachedRequests?.length){
                cachedRequests.find((cachedRequest) =>{
                    if(cachedRequest?.key === "getOfferAccepted"){
                        dispatch({type: setAccumulateRequest, payload: cachedRequests.filter((cached) => cached?.key !== "getOfferAccepted") })
                    }
                })
            }
            return;
        }

        dispatch({type: getOfferAcceptedRequest});

        const response = await axiosClient.get(`${api.offers}/${id}/offers/status?status=accepted`);

        const cachedRequests = getState().requests.data;
        if(cachedRequests?.length){
            cachedRequests.find((cachedRequest) =>{
                if(cachedRequest?.key === "getOfferAccepted"){
                    dispatch({type: setAccumulateRequest, payload: cachedRequests.filter((cached) => cached?.key !== "getOfferAccepted") })
                }
            })
        }

        dispatch({ type: getOfferAcceptedSuccess, payload: response?.data.data});
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({type: getOfferAcceptedFailure});
    }
};

export const cleanOfferAccepted = () => (dispatch: Dispatch) => _cleanOfferAccepted(dispatch);
const _cleanOfferAccepted = (dispatch: Dispatch) => dispatch({ type: cleanOfferAcceptedReducer });

export const getOffersBuy = (id: string) => (dispatch: Dispatch, getState: GetState) => _getOffersBuy(id, dispatch, getState);
const _getOffersBuy = async(id: string, dispatch: Dispatch, getState: GetState) => {
    try {
        const {fetchingFirstTime} = getState().auth;

        if(fetchingFirstTime){
            dispatch({type: accumulateRequest, payload: {function: getOffersBuy, params: {id}, key: "getOffersBuy"} });
            return;
        }

        dispatch({type: getOfferBuyRequest});

        const response = await axiosClient.get(`${api.publications}/${id}/offers/my-offers`);

        const cachedRequests = getState().requests.data;
        if(cachedRequests?.length){
            cachedRequests.find((cachedRequest) =>{
                if(cachedRequest?.key === "getOffersBuy"){
                    dispatch({type: setAccumulateRequest, payload: cachedRequests.filter((cached) => cached?.key !== "getOffersBuy") })
                }
            })
        };

        dispatch({ type: getOfferBuySuccess, payload: response?.data.data?.record_offer});
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({type: getOfferBuyFailure});
    }
};

export const cleanOffersBuy = () => (dispatch: Dispatch) => _cleanOffersBuy(dispatch);
const _cleanOffersBuy = (dispatch: Dispatch) => dispatch({ type: cleanOffersBuyReducer });

export const cleanOfferPost = () => (dispatch: Dispatch) => _cleanOfferPost(dispatch);
const _cleanOfferPost = (dispatch: Dispatch) => dispatch({ type: cleanOfferPostReducer });

export const getInspections = (id: string, type: InspectionTypes) => (dispatch: Dispatch, getState: GetState) => _getInspections(id, type, dispatch, getState)
const _getInspections = async (id: string, type: InspectionTypes, dispatch: Dispatch, getState: GetState) => {
    try {
        const {fetchingFirstTime} = getState().auth;

        if(fetchingFirstTime){
            dispatch({type: accumulateRequest, payload: {function: getInspections, params: {id, type}, key: "getInspections"} });
            return;
        }

        dispatch({type: getInspectionRequest });

        const response = await axiosClient.get(`${api.inspections}?${type === "buy" ? "transaction" : "publication"}=${id}`);

        const cachedRequests = getState().requests.data;
        if(cachedRequests?.length){
            cachedRequests.find((cachedRequest) =>{
                if(cachedRequest?.key === "getInspections"){
                    dispatch({type: setAccumulateRequest, payload: cachedRequests.filter((cached) => cached?.key !== "getInspections") })
                }
            })
        };

        dispatch({ type: getInspectionSuccess, payload: response?.data.data.inspections});
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: getInspectionFailure})
    }
}

export const getInspectionsFiles = (id: string) => (dispatch: Dispatch) => _getInspectionsFiles(id, dispatch)
const _getInspectionsFiles = async (id: string, dispatch: Dispatch) => {
    try {
        dispatch({type: getInspectionFilesRequest });

        const response = await axiosClient.get(`${api.inspections}/inspection-files?inspection=${id}`);

        dispatch({ type: getInspectionFilesSuccess, payload: response?.data.data.inspection_files});
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: getInspectionFilesFailure})
    }
}

export const cleanInspectionFiles = () => (dispatch: Dispatch) => _cleanInspectionFiles(dispatch);
const _cleanInspectionFiles = (dispatch: Dispatch) => dispatch({ type: cleanInspectionFilesReducer });

export const getLinzerAssigned = (id: string) => (dispatch: Dispatch, getState: GetState) => _getLinzerAssigned(id, dispatch, getState);
const _getLinzerAssigned = async (id: string, dispatch: Dispatch, getState: GetState) => {
    try {
        const {fetchingFirstTime} = getState().auth;

        if(fetchingFirstTime){
            dispatch({type: accumulateRequest, payload: {function: getLinzerAssigned, params: {id}, key: "getLinzerAssigned"} });
            return;
        }

        const userId = getState().auth.user?._id;
        if(!userId){
            const cachedRequests = getState().requests.data;
            if(cachedRequests?.length){
                cachedRequests.find((cachedRequest) =>{
                    if(cachedRequest?.key === "getLinzerAssigned"){
                        dispatch({type: setAccumulateRequest, payload: cachedRequests.filter((cached) => cached?.key !== "getLinzerAssigned") })
                    }
                })
            }
            return;
        }

        dispatch({type: getLinzerAssignedRequest });

        const response = await axiosClient.get(`${api.users}/${id}`);

        const cachedRequests = getState().requests.data;
        if(cachedRequests?.length){
            cachedRequests.find((cachedRequest) =>{
                if(cachedRequest?.key === "getLinzerAssigned"){
                    dispatch({type: setAccumulateRequest, payload: cachedRequests.filter((cached) => cached?.key !== "getLinzerAssigned") })
                }
            })
        }

        dispatch({type: getLinzerAssignedSuccess, payload: response?.data.data.user });
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({type: getLinzerAssignedFailure});

    }
}

export const deletePublication = (id: string) => (dispatch: Dispatch) => _deletePublication(id, dispatch);
const _deletePublication = async (id: string, dispatch: Dispatch) => {
    try {
        dispatch({type: deletePublicationRequest})

        await axiosClient.delete(`${api.publications}/${id}`);

        dispatch({type: deletePublicationSuccess})

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({type: deletePublicationFailure})
    }
};

export const cleanDeletePublication = () => (dispatch: Dispatch) => _cleanDeletePublication(dispatch);
const _cleanDeletePublication = (dispatch: Dispatch) => dispatch({type: cleanDeletePublicationReducer});

export const cleanUpdate = () => (dispatch: Dispatch) => _cleanUpdate(dispatch);
const _cleanUpdate = (dispatch: Dispatch) => dispatch({ type: cleanUpdateReducer });

export const cleanAll = () => (dispatch: Dispatch) => _cleanAll(dispatch);
const _cleanAll = (dispatch: Dispatch) => dispatch({ type: cleanAllReducer });

export const cleanOffer = () => (dispatch: Dispatch) => _cleanOffer(dispatch);
const _cleanOffer = (dispatch: Dispatch) => dispatch({ type: cleanOfferReducer });

export const cleanInspection = () => (dispatch: Dispatch) => _cleanInspection(dispatch);
const _cleanInspection = (dispatch: Dispatch) => dispatch({ type: cleanInspectionReducer });

export const getHistoryPublication = (id: string) => (dispatch: Dispatch, getState: GetState) => _getHistoryPublication(id, dispatch,getState);
const _getHistoryPublication = async (id: string, dispatch: Dispatch, getState: GetState) => {
    try {
        const {fetchingFirstTime} = getState().auth;

        if(fetchingFirstTime){
            dispatch({type: accumulateRequest, payload: {function: getHistoryPublication, params: {id}, key: "getHistoryPublication"} });
            return;
        }

        dispatch({type: getHistoryRequest})

        const response = await axiosClient.get(`${api.records}/${id}`);
        if(response.status !== 200){
            throw response;
        }

        const cachedRequests = getState().requests.data;
        if(cachedRequests?.length){
            cachedRequests.find((cachedRequest) =>{
                if(cachedRequest?.key === "getHistoryPublication"){
                    dispatch({type: setAccumulateRequest, payload: cachedRequests.filter((cached) => cached?.key !== "getHistoryPublication") })
                }
            })
        };

        dispatch({type: getHistorySuccess, payload: response?.data?.data?.records})
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({type: getHistoryFailure})
    }
};

export const cleanHistory = () => (dispatch: Dispatch) => _cleanHistory(dispatch);
const _cleanHistory = (dispatch: Dispatch) => dispatch({ type: cleanHistoryReducer });

export const getRecords = (id: string) => (dispatch: Dispatch, getState: GetState) => _getRecords(id, dispatch, getState);
const _getRecords = async (id: string, dispatch: Dispatch, getState: GetState) => {
    try {
        const {fetchingFirstTime} = getState().auth;

        if(fetchingFirstTime){
            dispatch({type: accumulateRequest, payload: {function: getRecords, params: {id}, key: "getRecords"} });
            return;
        }

        dispatch({type: getRecordsRequest});

        const response = await axiosClient.get(`${api.transactions}/records?publication=${id}`);

        const cachedRequests = getState().requests.data;
        if(cachedRequests?.length){
            cachedRequests.find((cachedRequest) =>{
                if(cachedRequest?.key === "getRecords"){
                    dispatch({type: setAccumulateRequest, payload: cachedRequests.filter((cached) => cached?.key !== "getRecords") })
                }
            })
        };

        dispatch({
            type: getRecordsSuccess, 
            payload: {
                address: response?.data?.data?.transaction?.address,
                date_start: response?.data?.data?.transaction?.date_start,
                date_end: response?.data?.data?.transaction?.date_end
            }
        })

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({type: getRecordsFailure, payload: errorResponse?.code})
    }
};
    
export const cleanGetRecords = () => (dispatch: Dispatch) => _cleanGetRecords(dispatch);
const _cleanGetRecords = (dispatch: Dispatch) => dispatch({ type: cleanGetRecordsReducer });

export const getViews = (id: string) => (dispatch: Dispatch, getState: GetState) => _getViews(id, dispatch,getState);
const _getViews = async (id: string, dispatch: Dispatch, getState: GetState) => {
    try {
        const {fetchingFirstTime} = getState().auth;

        if(fetchingFirstTime){
            dispatch({type: accumulateRequest, payload: {function: getViews, params: {id}, key: "getViews"} });
            return;
        }

        dispatch({type: getViewsRequest})

        const response = await axiosClient.get(`${api.reports}/views/${id}`);

        const cachedRequests = getState().requests.data;
        if(cachedRequests?.length){
            cachedRequests.find((cachedRequest) =>{
                if(cachedRequest?.key === "getViews"){
                    dispatch({type: setAccumulateRequest, payload: cachedRequests.filter((cached) => cached?.key !== "getViews") })
                }
            })
        };

        dispatch({type: getViewsSuccess, payload: response?.data?.data?.views?.views ?? 0});

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({type: getViewsFailure, payload: errorResponse?.code})
    }
};
    
export const cleanGetViews = () => (dispatch: Dispatch) => _cleanGetViews(dispatch);
const _cleanGetViews = (dispatch: Dispatch) => dispatch({ type: cleanGetViewsReducer });

export const getInterested = (id: string) => (dispatch: Dispatch,getState: GetState) => _getInterested(id, dispatch, getState);
const _getInterested = async (id: string, dispatch: Dispatch,getState: GetState) => {
    try {
        const {fetchingFirstTime} = getState().auth;

        if(fetchingFirstTime){
            dispatch({type: accumulateRequest, payload: {function: getInterested, params: {id}, key: "getInterested"} });
            return;
        }

        dispatch({type: getInterestedRequest})

        const response = await axiosClient.get(`${api.reports}/interested/publication/${id}`); 

        const cachedRequests = getState().requests.data;
        if(cachedRequests?.length){
            cachedRequests.find((cachedRequest) =>{
                if(cachedRequest?.key === "getInterested"){
                    dispatch({type: setAccumulateRequest, payload: cachedRequests.filter((cached) => cached?.key !== "getInterested") })
                }
            })
        };

        dispatch({type: getInterestedSuccess, payload: response?.data.data.interested?.total ?? 0})

} catch (error: any) {
    const errorResponse = error?.response?.data?.error;
    console.log(errorResponse);
    dispatch({type: getInterestedFailure, payload: errorResponse?.code})
    }
};
    
export const cleanGetInterested = () => (dispatch: Dispatch) => _cleanGetInterested(dispatch);
const _cleanGetInterested = (dispatch: Dispatch) => dispatch({ type: cleanGetInterestedReducer });

export const getCounterOffers = (id: string) => (dispatch: Dispatch) => _getCounterOffers(id, dispatch);
const _getCounterOffers = async (id: string, dispatch: Dispatch) => {
    try {
        dispatch({type: getCounterOffersRequest})

        const response = await axiosClient.get(`${api.publications}/counteroffers/offer/${id}`);
        
        dispatch({type: getCounterOffersSuccess, payload: response?.data.data.counteroffers});

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({type: getCounterOffersFailure, payload: errorResponse?.code})
    }
};
    
export const cleanGetCounterOffers = () => (dispatch: Dispatch) => _cleanGetCounterOffers(dispatch);
const _cleanGetCounterOffers = (dispatch: Dispatch) => dispatch({ type: cleanGetCounterOffersReducer });

export const putCounterOffers = (id: string, status: string) => (dispatch: Dispatch) => _putCounterOffers(id, status, dispatch);
const _putCounterOffers = async (id: string, status: string, dispatch: Dispatch) => {
    try {
        dispatch({type: putCounterOffersRequest});

        const body = JSON.stringify({status: status});

        const response = await axiosClient.put(`${api.publications}/counteroffers/${id}`, body);

        dispatch({type: putCounterOffersSuccess, payload: response?.data.data.counteroffer});

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({type: putCounterOffersFailure, payload: errorResponse?.code})
    }
};
    
export const cleanPutCounterOffers = () => (dispatch: Dispatch) => _cleanPutCounterOffers(dispatch);
const _cleanPutCounterOffers = (dispatch: Dispatch) => dispatch({ type: cleanPutCounterOffersReducer });

export const postCounterOffers = (id: string, price: number) => (dispatch: Dispatch) => _postCounterOffers(id, price, dispatch);
const _postCounterOffers = async (id: string, price: number, dispatch: Dispatch) => {
    try {
        dispatch({type: postCounterOffersRequest})

        const body = JSON.stringify({
            offer: id,
            price: price
        });

        const response = await axiosClient.post(`${api.publications}/counteroffers`, body);

        dispatch({
            type: postCounterOffersSuccess, 
            payload: response?.data.data.counteroffer
        })

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({type: postCounterOffersFailure, payload: errorResponse?.code})
    }
};
    
export const cleanPostCounterOffers = () => (dispatch: Dispatch) => _cleanPostCounterOffers(dispatch);
const _cleanPostCounterOffers = (dispatch: Dispatch) => dispatch({ type: cleanPostCounterOffersReducer });

export const pausePublication = (id: string, pause: boolean) => (dispatch: Dispatch) => _pausePublication(id, pause, dispatch);
const _pausePublication = async (id: string, pause: boolean, dispatch: Dispatch) => {
    try {
        dispatch({ type: pausePublicationRequest });

        const body = JSON.stringify({ paused: pause });

        const response = await axiosClient.patch(`${api.publications}/${id}/toggle-paused`, body);

        dispatch({ type: pausePublicationSuccess, payload: response?.data.data.publication.paused });

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse?.code);
        dispatch({ type: pausePublicationFailure, payload: errorResponse?.code });
    }
}

export const cleanPausePublication = () => (dispatch: Dispatch) => _cleanPausePublication(dispatch);
const _cleanPausePublication = (dispatch: Dispatch) => dispatch({ type: cleanPausePublicationReducer });