import { Dispatch } from "redux";
import { GetState } from "../config/store";
import { api } from "../config/api";
import {
    getNotificationsRequest,
    getNotificationsSuccess,
    getNotificationsFailure,
    putReadNotificationRequest,
    putReadNotificationSuccess,
    putReadNotificationFailure
} from "../reducers/notifications";
import { axiosClient } from "../config/axios";
import { accumulateRequest, setAccumulateRequest } from "../reducers/requests";
import { logOut } from "../reducers/auth";

export const getNotifications = (limit?: number) => (dispatch: Dispatch, getState: GetState) => _getNotifications(limit, dispatch, getState);
const _getNotifications = async (limit: number = 5, dispatch: Dispatch, getState: GetState) => {
    try {
        const {fetchingFirstTime} = getState().auth;

        if(fetchingFirstTime){
            dispatch({type: accumulateRequest, payload: {function: getNotifications, params: {limit}, key: "getNotifications"} });
            return;
        }
        const userId = getState().auth.user?._id;
        if(!userId){
            const cachedRequests = getState().requests.data;
            if(cachedRequests?.length){
                cachedRequests.find((cachedRequest) =>{
                    if(cachedRequest?.key === "getNotifications"){
                        dispatch({type: setAccumulateRequest, payload: cachedRequests.filter((cached) => cached?.key !== "getNotifications") })
                    }
                })
            }
            return;
        }

        dispatch({ type: getNotificationsRequest });
        const token = getState().auth?.token;
        if (!token) {
            dispatch({ type: getNotificationsFailure });
            return;
        }

        const response = await axiosClient.get(`${api.notifications}?limit=${limit}`);

        const cachedRequests = getState().requests.data;
        if(cachedRequests?.length){
            cachedRequests.find((cachedRequest) =>{
                if(cachedRequest?.key === "getNotifications"){
                    dispatch({type: setAccumulateRequest, payload: cachedRequests.filter((cached) => cached?.key !== "getNotifications") })
                }
            })
        }
        dispatch({type: getNotificationsSuccess, payload: response?.data?.data.notification});
    } catch (error: any) {
        const status = error?.response?.status;
        const errorMessage = error?.response?.data?.error || "Error al obtener notificaciones";
        
        if (status === 401) {
            // Si es error 401, dispatch logout y limpiar el estado
            dispatch({ type: logOut });
            dispatch({ type: getNotificationsFailure });
        } else {
            console.error("Error en getNotifications:", errorMessage);
            dispatch({ type: getNotificationsFailure});
        }
    }
};

export const putReadNotification = (notificationObject: {id: string, read: boolean, deleted: boolean}) => (dispatch: Dispatch, getState: GetState) => _putReadNotification(notificationObject, dispatch, getState);
const _putReadNotification = async (notificationObject: {id: string, read: boolean, deleted: boolean}, dispatch: Dispatch, getState: GetState) => {
    try {
        dispatch({type: putReadNotificationRequest});
        
        const body = JSON.stringify({read: notificationObject.read, deleted: notificationObject.deleted});

        const response = await axiosClient.put(`${api.notifications}/${notificationObject.id}`, body);

        dispatch({
            type: putReadNotificationSuccess, 
            payload: response?.data?.data.notification
        });
    } catch (error: any) {
        const status = error?.response?.status;
        const errorMessage = error?.response?.data?.error || "Error al actualizar notificación";
        
        if (status === 401) {
            dispatch({ type: logOut });
            dispatch({ type: putReadNotificationFailure});
        } else {
            console.error("Error en putReadNotification:", errorMessage);
            dispatch({ type: putReadNotificationFailure });
        }
    }
}